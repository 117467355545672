<template>
	<div>

		<div style="margin: 30px auto 5px auto;width: 150px">
			<van-image :src="require('@/assets/images/deposit_icon.png')" width="150px" />
		</div>

		<h4 class="h3_title">法定货币</h4>
	
		<van-cell-group inset>
			<van-cell :title="item.symbol" is-link v-for="(item, index) in fiat_money.children" :key="index" @click="goMethod(item.id)"/>
		</van-cell-group>

		<br />

		<h4 class="h3_title">数字货币</h4>
	
		<van-cell-group inset>
			<van-cell :title="item.symbol" is-link v-for="(item, index) in digital_money.children" :key="index" @click="goMethod(item.id)"/>
		</van-cell-group>

		<div class="common_block">
			<p style="text-align: right;color: blue;" @click="goHistory">上传回单</p>
		</div>

		<div v-if="deposit_tip" style="padding: 20px 16px">
			<p style="font-size: 13px;color: #666;margin-bottom: 15px">入金小贴士</p>
			<div v-html="deposit_tip" style="color: #666"></div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'deposit_index',
		data() {
			return {
				formItem: {
					token: "",
					currency_id: "",
				},

				fiat_money: {},
				digital_money: {},

				deposit_tip: "",
			}
		},
		mounted() {
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			} else {
				this.formItem.token = this.$route.query.token
				this.init()
			}
		},
		methods: {
			init() {
				this.$axios({
					method: 'post',
					url: 'cash_deposit/index', 
					data: this.formItem
				}).then ((res) => {
					if (res.success) {
						if (res.data.deposit_tip) {
							this.deposit_tip = decodeURIComponent(res.data.deposit_tip)
						}
						
						this.digital_money = res.data.digital_money
						this.fiat_money = res.data.fiat_money
					}
				})
			},
			goHistory() {
				this.$router.push('/deposit/history?token=' + this.formItem.token)
			},
			goMethod(currency_id) {
				this.formItem.currency_id = currency_id

				window.sessionStorage.setItem('deposit_info', JSON.stringify(this.formItem))

				this.$router.push('/deposit/method?token=' + this.formItem.token)
			}
		},
	}
</script>
